import * as React from 'react';
import { useRouter } from 'next/router';
import useViewer from 'hooks/useViewer';
import Head from 'components/Head';
import {
  useGetOptionFlowLazyQuery,
  useGetFreeOptionFlowLazyQuery,
  useGetUserForDashboardLazyQuery,
} from 'types/generated/client';
import { AuthStatus } from 'constants/auth';
import { isMarketHours } from 'utils/shared/time/marketHours';
import { hasDashboardAccess, hasCompletedOpraAgreement } from 'services/client/token';
import { ELECTRONIC_AGREEMENT_PAGE, OPTION_FLOW_PAGE, PageIcons } from 'constants/pages';
import FlowPageContainer from 'layouts/FlowPageContainer';
import OptionFlowTable from 'components/OptionFlowTable';
import { GetOptionFlowQuery } from 'types/generated/client';
import { getIsNativePlatform } from 'utils/client/mobile';
import useInlineScript from 'hooks/useInlineScript';
import { PRODUCT_TOUR_SCRIPT } from 'utils/client/productTour';
import { useApiGateway } from 'hooks/useApi';
import FloatingBox from 'components/auth/FloatingBox';
// import TopBanner from 'components/TopBanner/Banner';

const POLL_INTERVAL = 10000;
const TITLE = 'Live Options Flow and Unusual Options Activity';
const DESCRIPTION =
  'The #1 tool to detect unusual options activity using the best options flow tool - access for free. InsiderFinance is an award-nominated realtime order flow dashboard. Smart money real-time option flow and dark pool prints.';
// @ts-ignore
const INJECTED_PAGE_DATA = typeof window !== 'undefined' ? window?.injectedPageData : undefined;

const Placeholder = () => {
  return (
    <>
      <Head title={TITLE} description={DESCRIPTION} useCurrentUrl />

      <div></div>
    </>
  );
};

export default function OptionFlowPageContainer() {
  const viewer = useViewer();
  const router = useRouter();
  const [getOptionFlowQuery, { data, called, loading, refetch }] = useGetOptionFlowLazyQuery();
  const [
    getFreeOptionFlowQuery,
    { data: dataFree, called: calledFree, loading: isLoadingFree, refetch: refetchFree },
  ] = useGetFreeOptionFlowLazyQuery();
  const [getUserQuery, { data: userData }] = useGetUserForDashboardLazyQuery();
  const [injectedPageData, setInjectedPageData] = React.useState<GetOptionFlowQuery | undefined>(
    undefined,
  );
  const { get: getTotalFlowCount, data: totalFlowCountData } = useApiGateway(
    '/v1/products/option-flow/count',
  );
  const totalFlowCount = totalFlowCountData?.count || 0;
  const { get: getPaidFlowCount, data: paidFlowCountData } = useApiGateway(
    '/v1/products/option-flow/count',
  );
  const paidFlowCount = paidFlowCountData?.count || 0;
  const { get: getFreeFlowCount, data: freeFlowCountData } = useApiGateway(
    '/v1/products/option-flow-free/count',
  );
  const freeFlowCount = freeFlowCountData?.count || 0;
  const isLoadingAuth = viewer.status === AuthStatus.Loading;
  const shouldHaveFree =
    viewer.status === AuthStatus.Anonymous ||
    (viewer.status === AuthStatus.User && !hasDashboardAccess(viewer));
  const shouldHavePaid = viewer.status === AuthStatus.User && hasDashboardAccess(viewer);
  const isInitialFlowCalled = !isLoadingAuth && shouldHavePaid ? called : calledFree;

  React.useEffect(() => {
    if (INJECTED_PAGE_DATA) {
      setInjectedPageData(INJECTED_PAGE_DATA);
    }
  }, []);

  if (!INJECTED_PAGE_DATA && !getIsNativePlatform()) {
    // NOTE: This needs to be hoisted and in context or something to be only called once.
    // Or does the useEffect in the hook handle it with no dependencies?
    useInlineScript({
      body: PRODUCT_TOUR_SCRIPT,
    });
  }

  React.useEffect(() => {
    let interval: number | undefined;

    if (shouldHavePaid) {
      getPaidFlowCount();

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (isMarketHours()) {
          getPaidFlowCount();
        }
      }, POLL_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [shouldHavePaid]);

  React.useEffect(() => {
    let interval: number | undefined;

    if (shouldHaveFree) {
      getFreeFlowCount();
      getTotalFlowCount();

      // @ts-ignore issue with Node / browser types
      interval = setInterval(() => {
        if (isMarketHours()) {
          getFreeFlowCount();
          getTotalFlowCount();
        }
      }, POLL_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [shouldHaveFree]);

  React.useEffect(() => {
    if (isLoadingAuth) {
      return;
    }

    if (shouldHavePaid) {
      if (refetch) {
        refetch();
      } else {
        getOptionFlowQuery();
      }
    }
  }, [isLoadingAuth, shouldHavePaid, refetch, paidFlowCount]);

  React.useEffect(() => {
    if (isLoadingAuth) {
      return;
    }

    if (shouldHaveFree) {
      if (refetchFree) {
        refetchFree();
      } else {
        getFreeOptionFlowQuery();
      }
    }
  }, [isLoadingAuth, shouldHaveFree, refetchFree, freeFlowCount]);

  React.useEffect(() => {
    if (viewer.viewer?.uid) {
      getUserQuery({
        variables: {
          id: viewer.viewer.uid,
        },
      });
    }
  }, [viewer.viewer?.uid]);

  if (!injectedPageData && isLoadingAuth) {
    return <Placeholder />;
  }

  // if (!injectedPageData && viewer.status === AuthStatus.User && !hasDashboardAccess(viewer)) {
  //   router.push(PRICING_PAGE);
  //   return <Placeholder />;
  // }

  if (
    !injectedPageData &&
    viewer.status === AuthStatus.User &&
    hasDashboardAccess(viewer) &&
    !hasCompletedOpraAgreement(viewer)
  ) {
    router.push(ELECTRONIC_AGREEMENT_PAGE);
    return <Placeholder />;
  }

  return (
    <>
      <Head
        title={TITLE}
        description={DESCRIPTION}
        useCurrentUrl
        ogImage="https://www.insiderfinance.io/images/hero-dashboard.png"
      />
      {/* <div className="fixed left-0 top-0 z-50 w-full">
        <TopBanner showCloseIcon shouldShowOnScroll={false} />
      </div> */}
      <FlowPageContainer
        viewer={viewer}
        title="Flow"
        Icon={PageIcons.OptionFlow}
        trades={data?.option_flow || dataFree?.free_option_flow}
        dailyVolume={data?.daily_volume}
        loading={loading || isLoadingFree}
        called={isInitialFlowCalled}
        userDetails={userData?.users_by_pk}
        OptionFlowTable={OptionFlowTable}
        shouldShowDatePicker={false}
        injectedPageData={injectedPageData}
        pageHref={OPTION_FLOW_PAGE}
        isShowFlowDescription={!injectedPageData}
        totalFlowCount={totalFlowCount}
      />

      {!injectedPageData && <FloatingBox />}
    </>
  );
}
