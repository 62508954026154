import * as React from 'react';
import Link from 'components/Link';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import { TICKER_FLOW_PAGE, PRICING_PAGE } from 'constants/pages';
import TickerName from 'components/TickerName';
import { ScoreWrapper, HeatScore } from 'components/Score';
import { CallPutBackgroundMobile, CallPutText, TopPositionText } from 'components/Table';
import { FullTrade } from 'utils/shared/trades/types';
import classNames from 'styles/utils/classNames';

import { CardWrapper, Background, MutedText } from './styles';

export const MOBILE_CARD_PIXEL_HEIGHT = 134.2;

interface Props {
  trade: FullTrade;
  watchListTickers: string[];
}

const MobileOptionCard: React.FC<React.PropsWithChildren<Props>> = ({ trade }) => {
  return (
    <div className="relative">
      {trade.isBlur && (
        <div className="absolute bottom-0 top-0 z-10 my-auto flex w-full items-center justify-center gap-1 px-4 py-[0.4rem] text-center text-[.625rem] text-palette-black-7">
          <Link href={PRICING_PAGE} className="!text-palette-black-7 underline">
            Subscribe
          </Link>{' '}
          to see the full feed
        </div>
      )}
      <div
        style={{ height: `${MOBILE_CARD_PIXEL_HEIGHT}px`, overflow: 'hidden' }}
        className={classNames('pt-1', trade.isBlur && 'opacity-50 blur-sm')}
      >
        <CardWrapper isGoldenSweep={trade.isGoldenSweep} isUnusual={trade.isUnusual}>
          <Background
            isGoldenSweep={trade.isGoldenSweep}
            isUnusual={trade.isUnusual}
            className="p-4"
          >
            <div className="flex w-full">
              <div className="flex w-1/2 flex-col pr-1 text-xs leading-none">
                <div className="mb-2">
                  <MutedText>
                    {trade.date} {trade.time} {trade.timeSubscript}
                  </MutedText>
                </div>
                <div className="flex items-center">
                  <Link href={`${TICKER_FLOW_PAGE}/${trade.ticker}`}>
                    <TickerName name={trade?.tickerDetails?.name || ''}>
                      <CallPutBackgroundMobile type={trade.cp || ''}>
                        {trade.ticker}
                      </CallPutBackgroundMobile>
                    </TickerName>
                  </Link>
                  <div className="ml-2 font-bold">${abbreviateNumber(trade.premium)}</div>
                </div>
                <div className="mt-2 flex flex-col">
                  <div className="flex items-center leading-none">
                    <TopPositionText isTopPosition={trade.isTopPosition}>
                      {trade.size?.toLocaleString()} @ ${trade.price.toFixed(2)}{' '}
                    </TopPositionText>
                    <span className="ml-1 text-gray-400">{trade.tradeDirection}</span>
                  </div>
                  <div className="mt-2 leading-none">
                    <CallPutText className="inline" type={trade.cp || ''}>
                      {trade.cp?.toUpperCase()}
                    </CallPutText>{' '}
                    <span className="mt-1">{trade.orderType}</span>
                  </div>
                  <div className="mt-2 flex items-center">
                    <div className="mr-4 shrink-0 text-xs">Heat Score</div>
                    <div className="w-full pt-0.5" style={{ maxWidth: '64px' }}>
                      <ScoreWrapper>
                        <HeatScore scoreWidth={trade.scoreWidth} />
                      </ScoreWrapper>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-1/2 flex-col justify-between space-y-1 pl-1 text-xs leading-none">
                <div className="flex items-center justify-between">
                  <div>Expiration</div>
                  <div>{trade.expiryString}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Strike</div>
                  <div>${trade.strike}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Spot</div>
                  <div>${trade.spot.toFixed(2)}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>OTM</div>
                  <div>{Math.round(trade.otmPercent)}%</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Open Interest</div>
                  <div>{trade.openInterest?.toLocaleString()}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Implied Vol</div>
                  <div>{Math.round(trade.impliedVol * 100)}%</div>
                </div>
              </div>
            </div>
          </Background>
        </CardWrapper>
      </div>
    </div>
  );
};

export default MobileOptionCard;
