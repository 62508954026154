import styled from 'styled-components';
import { rgba } from 'styles/utils/color';

export const MutedText = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
  font-size: 85%;
`;

export const CardWrapper = styled.div<{
  hoverable?: boolean;
  isDark?: boolean;
  isUnusual: boolean;
  isGoldenSweep: boolean;
}>`
  background-color: ${({ theme, isDark }) =>
    isDark ? theme.color.backgroundSecondary : theme.color.paper};
  box-shadow: ${({ theme, isDark }) => (isDark ? theme.shadow.medium : theme.shadow.low)};
  transition: border 0.2s ease;

  &:hover {
    ${(props) => props.hoverable && `border: 1px solid ${props.theme.color.brand};`}
  }
`;

export const Background = styled.div<{
  hoverable?: boolean;
  isDark?: boolean;
  isUnusual: boolean;
  isGoldenSweep: boolean;
}>`
  background: ${({ isUnusual, isGoldenSweep, theme }) => {
    if (isGoldenSweep) {
      return `linear-gradient(
            -90deg,
            rgba(0, 0, 0, 0) 2%,
            ${rgba(theme.color.mixedSentiment, 0.19)} 32%,
            ${rgba(theme.color.mixedSentiment, 0.14)} 74%,
            rgba(0, 0, 0, 0) 98%);`;
    }

    if (isUnusual) {
      return `linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0) 2%,
        ${rgba(theme.color.unusualTrade, 0.19)} 32%,
        ${rgba(theme.color.unusualTrade, 0.14)} 74%,
        rgba(0, 0, 0, 0) 98%
      )`;
    }

    return 'transparent';
  }};
`;
