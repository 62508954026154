import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'components/Link';
import { LOGIN_PAGE, SIGNUP_PAGE } from 'constants/pages';
import { Transition } from '@headlessui/react';
import useViewer from 'hooks/useViewer';

interface FloatingBoxProps {
  next?: string;
  returnToCurrentPage?: boolean;
  showImmediately?: boolean;
  delay?: number;
}

export default function FloatingBox({
  next,
  returnToCurrentPage = true,
  showImmediately = false,
  delay = 5000,
}: FloatingBoxProps) {
  const [isShowing, setIsShowing] = useState(showImmediately);
  const router = useRouter();
  const { viewer } = useViewer();
  const isAnonymous = !viewer || viewer.isAnonymous;

  const loginHref = returnToCurrentPage
    ? `${LOGIN_PAGE}?next=${encodeURIComponent(router.asPath)}`
    : next
    ? `${LOGIN_PAGE}?next=${encodeURIComponent(next)}`
    : LOGIN_PAGE;
  const signupHref = returnToCurrentPage
    ? `${SIGNUP_PAGE}?next=${encodeURIComponent(router.asPath)}`
    : next
    ? `${SIGNUP_PAGE}?next=${encodeURIComponent(next)}`
    : SIGNUP_PAGE;

  useEffect(() => {
    if (!showImmediately && isAnonymous) {
      const timer = setTimeout(() => setIsShowing(true), delay);
      return () => clearTimeout(timer);
    }
  }, [showImmediately, delay, isAnonymous]);

  if (!isAnonymous) {
    return null;
  }

  return (
    <Transition
      show={isShowing}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed bottom-4 left-4 z-50 w-80 rounded-lg bg-palette-black-3 p-4 shadow-[0_0_10px_rgba(0,122,255,0.5)]">
        <h3 className="styled-font mb-2 text-lg font-semibold text-white">
          Log In or Create Account
        </h3>
        <p className="mb-4 text-sm text-gray-300">
          Unlock access to our free tools by creating an account.
        </p>
        <div className="flex space-x-2">
          <Link
            href={loginHref}
            className="hover:bg-brand-primary-dark flex-1 rounded bg-brand-primary px-4 py-2 text-center text-sm font-semibold text-white transition-colors"
          >
            Log In
          </Link>
          <Link
            href={signupHref}
            className="flex-1 rounded bg-white px-4 py-2 text-center text-sm font-semibold text-brand-primary transition-colors hover:bg-gray-100"
          >
            Create Account
          </Link>
        </div>
      </div>
    </Transition>
  );
}
