const MONDAY = 1;
const FRIDAY = 5;
// TIMES IN EST (with buffer)
const MARKET_START_HOUR = 9;
const MARKET_END_HOUR = 17; // consider market close at 5 to capture extra data

const marketTimezone = { locale: 'en-US', zoneObject: { timeZone: 'America/New_York' } };

export const nowInMarketTimezoneString = (timestamp?: number) => {
  if (timestamp) {
    return new Date(timestamp).toLocaleString(marketTimezone.locale, marketTimezone.zoneObject);
  } else {
    return new Date().toLocaleString(marketTimezone.locale, marketTimezone.zoneObject);
  }
};

export const nowInMarketTimezoneDate = () => {
  return new Date(nowInMarketTimezoneString());
};

export const isMarketHours = (timestamp?: number) => {
  const todayNy = nowInMarketTimezoneString(timestamp);
  const d = new Date(todayNy);

  // console.log('todayNy', todayNy, d, new Date().getHours());

  const day = d.getDay();
  const hour = d.getHours();
  const isWeekday = day >= MONDAY && day <= FRIDAY;
  const isAfterOpen = hour >= MARKET_START_HOUR;
  const isBeforeClose = hour <= MARKET_END_HOUR;

  // console.log(d, day, hour);
  // console.log({ isWeekday, isAfterOpen, isBeforeClose, hour });

  return isWeekday && isAfterOpen && isBeforeClose;
};
