import * as React from 'react';
import { RiLayoutRowLine } from 'react-icons/ri';
import { ImTable } from 'react-icons/im';
import Link from 'components/Link';
import { BsLightning } from 'react-icons/bs';
import { XIcon } from '@heroicons/react/solid';
import { AiFillStar } from 'react-icons/ai';
import { FaDownload } from 'react-icons/fa';
import { trackEvent } from 'services/client/analytics';
import { DOWNLOAD_FLOW } from 'services/client/analytics/events';
import { Switch } from '@headlessui/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import abbreviateNumber from 'utils/shared/number/abbreviateNumber';
import { getSectorShortName } from 'utils/shared/trades/sectors';
import { TICKER_FLOW_PAGE, PRICING_PAGE } from 'constants/pages';
import SectionTitle from 'components/SectionTitle';
import TickerName from 'components/TickerName';
import { ScoreWrapper, HeatScore } from 'components/Score';
import SpeakerButton from 'components/SpeakerButton';
import MobileOptionCard, { MOBILE_CARD_PIXEL_HEIGHT } from 'components/MobileOptionCard';
import { SortDirection } from 'utils/shared/trades/types';
import { convertToCSV, downloadCSV } from 'utils/client/csvUtils';
import {
  TableWrapper,
  TableCell,
  TableCellSmall,
  TableCellNoPad,
  TopPositionText,
  TableHeader,
  TableBody,
  HeaderCell,
  CallPutBackground,
  CallPutText,
  MutedText,
  HeaderCellWrapper,
  HeaderIcon,
  IconSortDesc,
  IconSortAsc,
  WatchListIcon,
  StyledTableBody,
} from 'components/Table';
import Modal from 'components/Modal/HeadlessUiModal';
import PermissionedBlock from 'components/PermissionedBlock';
import {
  FlowTableHeaderProps,
  FlowTableRowProps,
  FlowTableProps,
  MobileLayout,
  FullTrade,
} from 'utils/shared/trades/types';
import classNames from 'styles/utils/classNames';
import { FLOW_TABLE_HEADERS, DEFAULT_FLOW_KEY } from './props';

import { DashboardRow, IconButton } from './styles';

const TABLE_ROW_PIXEL_HEIGHT = 36.2;
const TITLE_NAMES = FLOW_TABLE_HEADERS;

const formatData = (data: FullTrade[]) => {
  return data.map((trade: any) => ({
    Time: `${trade.time} ${trade.timeSubscript}`,
    Ticker: trade.ticker,
    Expiry: trade.expiryString,
    'C/P': trade.cp,
    'Spot ($)': trade.spot?.toFixed(2),
    'Strike ($)': trade.strike?.toFixed(2),
    Otm: parseFloat((trade.otmPercent / 100).toFixed(4)),
    'Price ($)': trade.price.toFixed(2),
    Size: trade.size?.toFixed(2)?.toLocaleString(),
    'Open Interest': trade.openInterest?.toFixed(2)?.toLocaleString(),
    'Implied Vol': parseFloat(trade.impliedVol?.toFixed(4)),
    Type: trade.orderType,
    'Premium ($)': parseFloat(trade.premium?.toFixed(2)),
    Sector: getSectorShortName(trade.tickerDetails.sector),
    'Heat Score': trade?.heatScore?.toFixed(2),
  }));
};

const handleDownload = (data: FullTrade[]): void => {
  const formattedData = formatData(data);
  const csv = convertToCSV(formattedData);
  downloadCSV(csv, 'Realtime Option Flow');
};

const NeedsSubscription = ({ hiddenTrades }: { hiddenTrades?: number }) => {
  const tradesToConsider = Math.max(hiddenTrades || 0, 0);

  return (
    <div className="text-pretty bg-brand-primary px-2 py-1.5 text-center text-xs font-medium text-white md:px-0">
      <Link href={PRICING_PAGE}>
        {!!tradesToConsider && (
          <span className="mr-1 rounded-md bg-yellow-400 px-1.5 py-[.1rem] text-xs font-bold leading-none text-black no-underline shadow-md">
            {tradesToConsider} hidden trades
          </span>
        )}
        <span className="font-bold underline">Subscribe</span>
      </Link>{' '}
      to access the full feed. Trades are delayed by 30 minutes, and you are limited to the most
      recent.
    </div>
  );
};

const SubscribeModal = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: () => void }) => {
  return (
    <Modal isOpen={isOpen} handleClose={() => handleClose()}>
      <div className="relative rounded-lg bg-palette-black-1 p-4">
        <button onClick={() => handleClose()} className="absolute right-2 top-2">
          <XIcon className="w-5" />
        </button>
        <PermissionedBlock />
      </div>
    </Modal>
  );
};

const Header: React.FC<React.PropsWithChildren<FlowTableHeaderProps>> = React.memo(
  ({
    flowSortKey,
    setFlowSortKey,
    flowSortDirection,
    setFlowSortDirection,
    isWatchListOnly,
    setIsWatchListOnly,
    hasWatchListItems,
    isSoundEnabled,
    setIsSoundEnabled,
    mobileLayout,
    setMobileLayout,
    data,
    hasAccess,
  }) => {
    const [isDownloadModalOpen, setIsDownloadModalOpen] = React.useState(false);

    return (
      <>
        <TableHeader>
          <div className="mb-2 flex justify-between">
            <div className="flex">
              <div className="hidden items-center sm:flex">
                <SectionTitle
                  Icon={BsLightning}
                  title="Realtime Option Flow"
                  style={{ marginBottom: 0 }}
                />
              </div>
              <div className="flex items-center sm:hidden">
                <SectionTitle Icon={BsLightning} title="Option Flow" style={{ marginBottom: 0 }} />
              </div>
              <div className="ml-4 flex items-center space-x-1 sm:hidden">
                <IconButton
                  type="button"
                  isActive={mobileLayout === MobileLayout.Cards}
                  onClick={() => !!setMobileLayout && setMobileLayout(MobileLayout.Cards)}
                >
                  <RiLayoutRowLine />
                </IconButton>
                <IconButton
                  type="button"
                  isActive={mobileLayout === MobileLayout.Table}
                  onClick={() => !!setMobileLayout && setMobileLayout(MobileLayout.Table)}
                >
                  <ImTable />
                </IconButton>
              </div>
              <Switch.Group as="div" className="ml-4 flex items-center">
                <Switch
                  checked={isWatchListOnly}
                  onChange={setIsWatchListOnly}
                  className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'bg-yellow-400' : 'bg-gray-600'}
                    pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out
                  `}
                  />
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'translate-x-3' : 'translate-x-0'} ${
                      isWatchListOnly ? 'bg-white' : 'bg-gray-300'
                    } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-400 shadow ring-0 transition-transform duration-200 ease-in-out`}
                  />
                </Switch>
                <div
                  className={`ml-2 text-xs ${
                    isWatchListOnly ? 'text-yellow-400' : 'text-gray-600'
                  }`}
                >
                  <AiFillStar />
                </div>
                {isWatchListOnly && !hasWatchListItems && (
                  <Switch.Label as="span" className="ml-2 leading-none" style={{ lineHeight: 0 }}>
                    <span className="text-xs font-medium leading-none text-yellow-400">
                      Add items to your watchlist to appear in the feed
                    </span>
                  </Switch.Label>
                )}
              </Switch.Group>
            </div>
            <div className="absolute right-10 top-2">
              <SpeakerButton
                className="shadow-sm"
                isSoundEnabled={isSoundEnabled}
                setIsSoundEnabled={setIsSoundEnabled}
              />
            </div>
            <div>
              <button
                className="flex h-6 items-center justify-center rounded-[4px] bg-palette-border px-2 text-[.65rem] shadow-sm"
                onClick={() => {
                  trackEvent(DOWNLOAD_FLOW, { hasAccess });

                  if (!hasAccess) {
                    return setIsDownloadModalOpen(true);
                  }

                  if (data) {
                    handleDownload(data);
                  }
                }}
              >
                <FaDownload /> <span className="ml-2 text-[.6rem] font-semibold">Download</span>
              </button>
            </div>
          </div>
          <DashboardRow removePad>
            {TITLE_NAMES.map((t, i) => {
              const isActiveSort =
                t.sortKey === flowSortKey && flowSortDirection !== SortDirection.None;
              return (
                <HeaderCellWrapper
                  key={t.name}
                  removePad={i + 1 === TITLE_NAMES.length}
                  isActiveSort={isActiveSort}
                  onClick={() => {
                    if (flowSortKey !== t.sortKey) {
                      setFlowSortKey(t.sortKey);
                      setFlowSortDirection(SortDirection.Desc);
                    } else if (
                      flowSortKey === t.sortKey &&
                      flowSortDirection === SortDirection.Desc
                    ) {
                      setFlowSortDirection(SortDirection.Asc);
                    } else if (
                      flowSortKey === t.sortKey &&
                      flowSortDirection === SortDirection.Asc
                    ) {
                      setFlowSortKey(DEFAULT_FLOW_KEY);
                      setFlowSortDirection(SortDirection.None);
                    }
                  }}
                >
                  <HeaderCell>
                    <span className="cell-name">{t.name}</span>
                    {isActiveSort && flowSortDirection === SortDirection.Desc && (
                      <HeaderIcon>
                        <IconSortDesc />
                      </HeaderIcon>
                    )}
                    {isActiveSort && flowSortDirection === SortDirection.Asc && (
                      <HeaderIcon>
                        <IconSortAsc />
                      </HeaderIcon>
                    )}
                  </HeaderCell>
                </HeaderCellWrapper>
              );
            })}
          </DashboardRow>
        </TableHeader>
        <SubscribeModal
          isOpen={isDownloadModalOpen}
          handleClose={() => setIsDownloadModalOpen(false)}
        />
      </>
    );
  },
);

const Row: React.FC<React.PropsWithChildren<FlowTableRowProps>> = ({
  trade,
  watchListTickers,
  trackRowAsAnimated,
  hasBeenAnimated,
}) => {
  const isWatchListTrade = !!trade.ticker && watchListTickers.includes(trade.ticker);

  React.useEffect(() => {
    return () => {
      if (trackRowAsAnimated && !hasBeenAnimated) {
        trackRowAsAnimated();
      }
    };
  }, [hasBeenAnimated, trackRowAsAnimated]);

  return (
    <div className="relative">
      {trade.isBlur && (
        <div className="absolute bottom-0 top-0 z-10 my-auto flex w-full items-center justify-center gap-1 px-4 py-[0.4rem] text-center text-[.625rem] text-palette-black-7">
          <Link href={PRICING_PAGE} className="!text-palette-black-7 underline">
            Subscribe
          </Link>{' '}
          to see the full feed
        </div>
      )}
      <DashboardRow
        tableBodyRow
        isAnimated={!hasBeenAnimated}
        isUnusual={trade.isUnusual}
        isGoldenSweep={trade.isGoldenSweep}
        className={trade.isBlur ? 'opacity-50 blur-sm' : ''}
      >
        <TableCell>
          <MutedText>
            {trade.time} {trade.timeSubscript}
          </MutedText>
        </TableCell>
        <TableCell className="relative">
          {isWatchListTrade && (
            <WatchListIcon className="absolute text-yellow-400">
              <AiFillStar />
            </WatchListIcon>
          )}
          <Link href={`${TICKER_FLOW_PAGE}/${trade.ticker}`}>
            <TickerName name={trade?.tickerDetails?.name || ''}>
              <CallPutBackground type={trade.cp || ''}>{trade.ticker}</CallPutBackground>
            </TickerName>
          </Link>
        </TableCell>
        <TableCell>{trade.expiryString}</TableCell>
        <TableCell>{trade.cp}</TableCell>
        <TableCell>${trade.spot.toFixed(2)}</TableCell>
        <TableCell>${trade.strike}</TableCell>
        <TableCell>{Math.round(trade.otmPercent)}%</TableCell>
        <TableCell>
          ${trade.price.toFixed(2)}{' '}
          <span className="ml-1 text-gray-400">{trade.tradeDirection}</span>
        </TableCell>
        <TableCell>
          <TopPositionText isTopPosition={trade.isTopPosition}>
            {trade.size?.toLocaleString()}
          </TopPositionText>
        </TableCell>
        <TableCell>{trade.openInterest?.toLocaleString()}</TableCell>
        <TableCell>{Math.round(trade.impliedVol * 100)}%</TableCell>
        <TableCellSmall>{trade.orderType}</TableCellSmall>
        <TableCell>
          <CallPutText type={trade.cp || ''}>${abbreviateNumber(trade.premium)}</CallPutText>
        </TableCell>
        <TableCell>{getSectorShortName(trade?.tickerDetails?.sector || '')}</TableCell>
        <TableCellNoPad>
          <ScoreWrapper>
            <HeatScore scoreWidth={trade.scoreWidth} />
          </ScoreWrapper>
        </TableCellNoPad>
      </DashboardRow>
    </div>
  );
};

const OptionFlowTable: React.FC<React.PropsWithChildren<FlowTableProps>> = ({
  trades,
  flowSortKey,
  setFlowSortKey,
  flowSortDirection,
  setFlowSortDirection,
  isWatchListOnly,
  setIsWatchListOnly,
  hasWatchListItems,
  isSoundEnabled,
  setIsSoundEnabled,
  watchListTickers,
  totalFlowCount,
  hasAccess,
}) => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = React.useState(false);
  const [hasLoadedTrades, setHasLoadedTrades] = React.useState(false);
  const [animatedRows, setAnimatedRows] = React.useState<Record<string, boolean>>({});
  const [mobileLayout, setMobileLayout] = React.useState(MobileLayout.Cards);

  React.useEffect(() => {
    if (!hasLoadedTrades && trades?.length) {
      const rows: Record<string, boolean> = {};
      trades.forEach((t) => {
        rows[t.id] = true;
      });
      setHasLoadedTrades(true);
      setAnimatedRows(rows);
    }
  }, [trades, hasLoadedTrades]);

  const RenderRow = React.useCallback(
    ({ index, style }: any) => {
      const trade = trades[index];
      return (
        <div style={style}>
          <Row
            key={trade.id}
            trade={trade}
            watchListTickers={watchListTickers}
            hasBeenAnimated={animatedRows[trade.id]}
            trackRowAsAnimated={() => {
              setAnimatedRows((prevState) => ({ ...prevState, [trade.id]: true }));
            }}
          />
        </div>
      );
    },
    [trades, animatedRows, watchListTickers],
  );

  const RenderCard = React.useCallback(
    ({ index, style }: any) => {
      const trade = trades[index];
      return (
        <div style={style}>
          <MobileOptionCard key={trade.id} trade={trade} watchListTickers={watchListTickers} />
        </div>
      );
    },
    [trades, watchListTickers],
  );

  return (
    <>
      <TableWrapper
        className={classNames(
          'h-full w-full flex-auto flex-col overflow-x-auto overflow-y-hidden sm:flex',
          mobileLayout === MobileLayout.Cards ? 'hidden' : 'flex',
        )}
      >
        <Header
          flowSortKey={flowSortKey}
          setFlowSortKey={setFlowSortKey}
          flowSortDirection={flowSortDirection}
          setFlowSortDirection={setFlowSortDirection}
          isWatchListOnly={isWatchListOnly}
          setIsWatchListOnly={setIsWatchListOnly}
          hasWatchListItems={hasWatchListItems}
          isSoundEnabled={isSoundEnabled}
          setIsSoundEnabled={setIsSoundEnabled}
          mobileLayout={mobileLayout}
          setMobileLayout={setMobileLayout}
          data={trades}
          hasAccess={hasAccess}
        />
        <TableBody animateRows className="h-full w-full">
          <div className="h-full w-full overflow-hidden">
            {!hasAccess && (
              <NeedsSubscription hiddenTrades={(totalFlowCount || 0) - trades.length} />
            )}
            <AutoSizer>
              {({ width, height }) => (
                <FixedSizeList
                  height={height}
                  itemCount={trades.length}
                  itemSize={TABLE_ROW_PIXEL_HEIGHT}
                  width={width}
                >
                  {RenderRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </TableBody>
      </TableWrapper>
      <div
        className={classNames(
          'h-full w-full flex-auto flex-col overflow-hidden rounded sm:hidden',
          mobileLayout === MobileLayout.Table ? 'hidden' : 'flex',
        )}
      >
        <TableHeader>
          <div className="mb-2 flex justify-between">
            <div className="flex">
              <div className="flex items-center">
                <SectionTitle Icon={BsLightning} title="Option Flow" style={{ marginBottom: 0 }} />
                <div className="ml-4 flex items-center space-x-1 sm:hidden">
                  <IconButton
                    type="button"
                    isActive={mobileLayout === MobileLayout.Cards}
                    onClick={() => !!setMobileLayout && setMobileLayout(MobileLayout.Cards)}
                  >
                    <RiLayoutRowLine />
                  </IconButton>
                  <IconButton
                    type="button"
                    isActive={mobileLayout === MobileLayout.Table}
                    onClick={() => !!setMobileLayout && setMobileLayout(MobileLayout.Table)}
                  >
                    <ImTable />
                  </IconButton>
                </div>
              </div>
              <Switch.Group as="div" className="ml-4 flex items-center">
                <Switch
                  checked={isWatchListOnly}
                  onChange={setIsWatchListOnly}
                  className="group relative inline-flex h-3 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'bg-yellow-400' : 'bg-gray-600'}
                      pointer-events-none absolute mx-auto h-2 w-5 rounded-full transition-colors duration-200 ease-in-out
                    `}
                  />
                  <span
                    aria-hidden="true"
                    className={`${isWatchListOnly ? 'translate-x-3' : 'translate-x-0'} ${
                      isWatchListOnly ? 'bg-white' : 'bg-gray-300'
                    } pointer-events-none absolute left-0 inline-block h-3 w-3 transform rounded-full border border-gray-400 shadow ring-0 transition-transform duration-200 ease-in-out`}
                  />
                </Switch>
                <div
                  className={`ml-2 text-xs ${
                    isWatchListOnly ? 'text-yellow-400' : 'text-gray-600'
                  }`}
                >
                  <AiFillStar />
                </div>
                {isWatchListOnly && !hasWatchListItems && (
                  <Switch.Label as="span" className="ml-2 leading-none" style={{ lineHeight: 0 }}>
                    <span className="text-xs font-medium leading-none text-yellow-400">
                      Add items to your watchlist to appear in the feed
                    </span>
                  </Switch.Label>
                )}
              </Switch.Group>
            </div>
            <div className="absolute right-10 top-2">
              <SpeakerButton
                className="shadow-sm"
                isSoundEnabled={isSoundEnabled}
                setIsSoundEnabled={setIsSoundEnabled}
              />
            </div>
            <div>
              <button
                className="flex h-6 items-center justify-center rounded-[4px] bg-palette-border px-2 text-[.65rem] shadow-sm"
                onClick={() => {
                  trackEvent(DOWNLOAD_FLOW, { hasAccess });

                  if (!hasAccess) {
                    return setIsDownloadModalOpen(true);
                  }

                  if (trades) {
                    handleDownload(trades);
                  }
                }}
              >
                <FaDownload /> <span className="ml-2 text-[.6rem] font-semibold">Download</span>
              </button>
            </div>
          </div>
        </TableHeader>
        <StyledTableBody className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
          {!hasAccess && <NeedsSubscription hiddenTrades={(totalFlowCount || 0) - trades.length} />}
          <AutoSizer>
            {({ width, height }) => (
              <FixedSizeList
                height={height}
                itemCount={trades.length}
                itemSize={MOBILE_CARD_PIXEL_HEIGHT}
                width={width}
              >
                {RenderCard}
              </FixedSizeList>
            )}
          </AutoSizer>
        </StyledTableBody>
      </div>
      <SubscribeModal
        isOpen={isDownloadModalOpen}
        handleClose={() => setIsDownloadModalOpen(false)}
      />
    </>
  );
};

export default OptionFlowTable;
