import styled from 'styled-components';
import { TableRow } from 'components/Table';
import { Button as BaseButton } from 'styles/elements';
import { rgba } from 'styles/utils/color';

export const IconButton = styled(BaseButton)<{ isActive: boolean }>`
  padding: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-width: 1px;
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.buttonPrimary : theme.color.border};

  &:focus {
    outline: none;
  }
`;

export const DashboardRow = styled(TableRow)<{
  tableBodyRow?: boolean;
  isUnusual?: boolean;
  isGoldenSweep?: boolean;
}>`
  grid-template-columns:
    minmax(56px, 0.9fr) minmax(56px, 1fr) minmax(72px, 1.2fr) minmax(32px, 0.7fr) minmax(56px, 1fr) minmax(
      48px,
      0.8fr
    )
    minmax(52px, 0.7fr)
    minmax(56px, 1.15fr)
    repeat(6, minmax(56px, 1fr)) minmax(40px, 0.8fr);
  background: ${({ isUnusual, isGoldenSweep, theme }) => {
    if (isGoldenSweep) {
      return `linear-gradient(
            -90deg,
            rgba(0, 0, 0, 0) 2%,
            ${rgba(theme.color.mixedSentiment, 0.19)} 32%,
            ${rgba(theme.color.mixedSentiment, 0.14)} 74%,
            rgba(0, 0, 0, 0) 98%);`;
    }

    if (isUnusual) {
      return `linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0) 2%,
        ${rgba(theme.color.unusualTrade, 0.19)} 32%,
        ${rgba(theme.color.unusualTrade, 0.14)} 74%,
        rgba(0, 0, 0, 0) 98%
      )`;
    }

    return 'transparent';
  }};
`;
