const INTRO_TOUR_TOKEN = 'b799c7570f431c9edc965242409c6ba5';
export const PRODUCT_TOUR_SCRIPT = `
(function (w, d, s) {
  var a = d.getElementsByTagName('head')[0];
  var r = d.createElement('script');
  r.async = 1;
  r.src = s;
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = "${INTRO_TOUR_TOKEN}";
          a.appendChild(r);
})(window, document, "https://www.usetiful.com/dist/usetiful.js");
`;
